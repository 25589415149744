import React from 'react';
import { Tooltip, Typography } from '@mui/material';

const WordWithTooltip = ({ word, translation, explanation, position, isActive, isRevealed, onClick, vocabulary_status, isHighlighted }) => {
    const getBorderClass = () => {
        switch (vocabulary_status) {
           
            case 'hot':
                return 'border-yellow-500';
            default:
                return 'border-transparent';
        }
    };

    const getHighlightClass = () => {
        if (isHighlighted) {
            return 'bg-blue-200 border-blue-400'; // Adjust colors as needed
        }
        return '';
    };

    return (
        <Tooltip
            title={
                <Typography variant="body2">
                    <strong>Translation:</strong> {translation}<br />
                    <strong>Explanation:</strong> {explanation}
                </Typography>
            }
            open={isActive}
            disableFocusListener
            disableHoverListener
            disableTouchListener
        >
            <span
                onClick={() => onClick(position)}
                className={`word-tooltip cursor-pointer px-1 py-0.5 rounded transition-colors duration-200 inline-block mx-0.5 my-0.5
                    ${isRevealed ? 'highlighted bg-green-100 border border-green-600 text-green-700' : `border ${getBorderClass()}`}
                    ${getHighlightClass()}`}
            >
                {word}
            </span>
        </Tooltip>
    );
};

export default WordWithTooltip;
