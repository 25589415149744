import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faPlay, faPause, faHeadphones } from '@fortawesome/free-solid-svg-icons';

const AudioPlayer = ({ audioUrl, wordTranscripts, onWordHighlight }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef(null);

  useEffect(() => {
    const audioElement = audioRef.current;
    const handleEnded = () => {
      setIsPlaying(false);
      setCurrentTime(0);
    };
    const handleTimeUpdate = () => {
      const newTime = audioElement.currentTime * 1000;
     
      setCurrentTime(newTime);
    };
    
    audioElement.addEventListener('ended', handleEnded);
    audioElement.addEventListener('timeupdate', handleTimeUpdate);
    
    return () => {
      audioElement.removeEventListener('ended', handleEnded);
      audioElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, []);

  useEffect(() => {
    if (wordTranscripts && wordTranscripts.length > 0) {
       
      const currentWord = wordTranscripts.findIndex(
        (word, index) => 
          currentTime >= (index > 0 ? wordTranscripts[index - 1][1] : 0) && 
          currentTime < word[1]
      );
      if (currentWord !== -1) {
        onWordHighlight(currentWord+1);
      }
    }
  }, [currentTime, wordTranscripts, onWordHighlight]);

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="bg-gray-100 rounded-lg p-4">
      <div className="flex items-center justify-center mb-2">
        <FontAwesomeIcon icon={faHeadphones} className="text-gray-600 mr-2" /> 
        <span className="text-gray-700 font-medium">Listening Comprehension</span>
      </div>
      <div className="flex items-center justify-center">
        <button
          onClick={togglePlay}
          className="bg-blue-500 hover:bg-blue-600 text-white rounded-full p-3 focus:outline-none focus:ring-2 focus:ring-blue-300"
          aria-label={isPlaying ? 'Pause' : 'Play'}
        >
          {isPlaying ? (
            <FontAwesomeIcon icon={faPause} className="w-6 h-6" /> 
          ) : (
            <FontAwesomeIcon icon={faPlay} className="w-6 h-6" />
          )}
        </button>
        <div className="ml-3 text-sm text-gray-600">
          {isPlaying ? 'Now playing' : 'Click to play'}
        </div>
      </div>
      <audio ref={audioRef} src={audioUrl} />
    </div>
  );
};

export default AudioPlayer;
