// AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import authService from '../authService';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUser = async () => {
            const fetchedUser = await authService.getCurrentUser();
            
            setUser(fetchedUser);
            setLoading(false);
        };
        fetchUser();
    }, []);

    const login = async (username, password) => {
        const user = await authService.login(username, password);
        
        setUser(user);
    };

    const register = async (newUser) => {
        const preparedUser = {
            name: newUser.name,
            username: newUser.username,
            email: newUser.email,
            phone_number: newUser.phoneNumber,
            preferred_language: newUser.preferredLanguage,
            target_language: newUser.targetLanguage,
            current_target_language_level: newUser.currentTargetLanguageLevel,
            password: newUser.password
        };
    
        try {
            await authService.register(preparedUser);
            
            await login(newUser.username, newUser.password);
            return { success: true };
            
        } catch (error) {
            if (error.response?.status === 400) {
                return { success: false, error: error.response.data.detail };
            } else {
                console.error('Error during registration:', error);
                return { success: false, error: 'Error registering user. Please try again.' };
            }
        }
    };
    

    const logout = () => {
        authService.logout();
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ user, loading, login, register, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext };
