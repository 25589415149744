import { CircularProgress } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../contexts/authContext';
import SocialMediaPost from './socialMediaPost';

const SocialMediaFeed = () => {
    const [postData, setPostData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [clickedWords, setClickedWords] = useState(new Set());
    const [voteStatus, setVoteStatus] = useState(0);

    const { user } = useContext(AuthContext);

    const fetchPostData = async () => {
        setLoading(true);
        try {
            const response = await fetch(`/api/posts/`, {
                headers: {
                    'Authorization': `Bearer ${user.access_token}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setPostData(data[0]);
            setVoteStatus(0); // Reset vote status for new post
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPostData();
    }, []); // Empty dependency array to fetch only on mount

    const handleWordClick = (position) => {
        setClickedWords(prev => new Set(prev).add(position));
    };

    const handleVote = (newVoteStatus) => {
        if (newVoteStatus === voteStatus) {
            setVoteStatus(0); // Toggle off if clicking the same vote
        } else {
            setVoteStatus(newVoteStatus);
        }
    };

    const uploadPostHistory = async (postData, clickedWords, voteStatus) => {
        const vocabularyStatus = postData.vocabulary.map(word => ({
            word: word.base_word,
            revealed: clickedWords.has(word.position)
        }));

        const postHistory = {
            user_id: null,
            post_id: postData._id,
            vocabulary_status: vocabularyStatus,
            vote_status: voteStatus
        };

        await fetch('/api/posts/history/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.access_token}`
            },
            body: JSON.stringify(postHistory)
        });

       
    };

    const showNextPost = async () => {
        
        if (postData) {
            try {
                // Create deep copies of postData and clickedWords
                // non primitives like postData and clickedWords are passed by reference, we need to deep copy them
                const postDataClone = JSON.parse(JSON.stringify(postData));
                const clickedWordsClone = new Set([...clickedWords]);
                uploadPostHistory(postDataClone, clickedWordsClone, voteStatus);
                 // Reset states and fetch next post
                setClickedWords(new Set());
                setVoteStatus(0);
                // Directly fetch the next post
                fetchPostData();
            } catch (err) {
                console.error('Failed to send post history:', err);
                setError('Failed to load next post. Please try again.');
            }
        }
    };

    if (loading) return (
        <div className="flex justify-center items-center h-screen">
            <CircularProgress />
        </div>
    );
    if (error) return <div className="text-red-500 text-center">{error}</div>;

    return (
        <div className="container mx-auto p-4">
            <div className="space-y-6">
                {postData ? (
                    <SocialMediaPost 
                        postData={postData} 
                        clickedWords={clickedWords}
                        onWordClick={handleWordClick}
                        onVote={handleVote}
                        voteStatus={voteStatus}
                    />
                ) : (
                    <div>No posts available.</div>
                )}
                <button
                    onClick={showNextPost}
                    className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors"
                >
                    Show Next Post
                </button>
            </div>
        </div>
    );
};

export default SocialMediaFeed;
