import React, { useState, useContext } from 'react';
import { Tooltip } from 'react-tooltip';
import { AuthContext } from '../contexts/authContext';

const CommentSpace = () => {
    const [comment, setComment] = useState('');
    const [aiResponse, setAiResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const { user } = useContext(AuthContext);

    const handleCheckComment = async () => {
        if (!comment) {
            console.error('Comment is empty or null');
            return;
        }
    
        setIsLoading(true);
        try {
            const response = await fetch('/api/posts/correct-comment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.access_token}`
                },
                body: JSON.stringify({ post_comment: comment }),
            });
    
            if (!response.ok) {  // Check if response status is not ok
                const errorData = await response.json();
                console.error('Server responded with an error:', errorData);
            } else {
                const data = await response.json();
                setAiResponse(data);
            }
    
        } catch (error) {
            console.error('Error checking comment:', error);
        }
        setIsLoading(false);
    };

    return (
        <div className="mt-6 bg-white rounded-lg shadow-lg p-6">
            <textarea
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                rows="4"
                placeholder="Write your comment here..."
                value={comment}
                onChange={(e) => setComment(e.target.value)}
            />
            
            <button
                className="mt-3 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors disabled:bg-gray-400"
                onClick={handleCheckComment}
                disabled={!comment.trim() || isLoading}
            >
                {isLoading ? 'Checking...' : 'Check comment with AI'}
            </button>

            {aiResponse && (
                <div className="mt-4">
                    <div className="p-4 bg-gray-50 rounded-lg">
                        <h3 className="text-lg font-semibold mb-2">Corrected Comment:</h3>
                        <p className="text-gray-700">{aiResponse.correctedComment}</p>
                        
                        <button
                            className="mt-3 text-blue-500 hover:text-blue-600"
                            data-tooltip-id="explanation-tooltip"
                        >
                            Show full explanation
                        </button>

                        <Tooltip
                            id="explanation-tooltip"
                            place="bottom"
                            className="max-w-md bg-white text-gray-700 p-4 rounded-lg shadow-lg border border-gray-200"
                        >
                            {aiResponse.notes}
                        </Tooltip>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CommentSpace; 