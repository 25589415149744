import React, { useState } from 'react';
import VotingAndMenu from './votingAndMenu';
import PostContent from './postContent';
import AudioPlayer from './AudioPlayer';
import CommentSpace from './CommentSpace';

// SocialMediaPost Component
const SocialMediaPost = ({ postData, clickedWords, onWordClick, onVote, voteStatus }) => {
    const [highlightedWord, setHighlightedWord] = useState(-1);

    const handleWordHighlight = (index) => {
        setHighlightedWord(index);
    };

    return (
        <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
            <VotingAndMenu votes={postData.votes} onVote={onVote} voteStatus={voteStatus} level={postData.level} />
            <PostContent 
                postData={postData} 
                clickedWords={clickedWords} 
                onWordClick={onWordClick} 
                highlightedWord={highlightedWord}
            />
            {postData.audio_link && (
                <div className="mt-6">
                    <AudioPlayer 
                        audioUrl={postData.audio_link} 
                        wordTranscripts={postData.word_timestamps}
                        onWordHighlight={handleWordHighlight}
                    />
                </div>
            )}
            <CommentSpace />
        </div>
    );
};

export default SocialMediaPost;
