import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../contexts/authContext';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const NavButton = ({ onClick, children }) => (
    <button
      onClick={onClick}
      className="w-full px-4 py-2 text-left bg-indigo-500 rounded-md hover:bg-indigo-700 mt-2 md:mt-0 md:ml-2 md:w-auto"
    >
      {children}
    </button>
  );

  return (
    <header className="bg-indigo-600 text-white px-4 py-4 shadow-md">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <FontAwesomeIcon icon={faLanguage} className="mr-3 text-2xl" />
            <h1 className="text-xl md:text-2xl font-semibold">LinguagenAI Simulated Social</h1>
          </div>
          <button onClick={toggleMenu} className="md:hidden">
            <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} className="text-2xl" />
          </button>
        </div>
        <nav className={`${isMenuOpen ? 'block' : 'hidden'} md:block mt-4 md:mt-0`}>
          <div className="md:flex md:items-center">
            {user ? (
              <>
                <NavButton onClick={logout}>Logout</NavButton>
                <NavButton onClick={() => navigate('/profile')}>Profile</NavButton>
                <NavButton onClick={() => navigate('/dashboard')}>Dashboard</NavButton>
              </>
            ) : (
              <NavButton onClick={() => navigate('/login')}>Login</NavButton>
            )}
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
